<template>
  <div class="form-elements">
      <vuestic-widget :headerText="$t('view.user.title')">
          <div class="row">
              <div class="col-md-3">
                  <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.user.back' | translate}}</button>
              </div>
          </div>

      </vuestic-widget>
      <div class="row">
          <div class="col-md-12">
              <vuestic-widget :headerText="'view.user.new' | translate">
                  <form @submit.prevent="editUser" autocomplete="off">
                      <div class="row">
                          <div class="col-md-12">
                              <fieldset>
                                  <div class="row">
                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                                              <div class="input-group">
                                                  <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                  <label class="control-label" for="name">{{'view.company.fields.name' | translate}} </label><i class="bar"></i>
                                                  <small v-show="errors.has('name')" class="help text-danger">
                                                      {{ errors.first('name') }}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulEmailValid}">
                                              <div class="input-group">
                                                  <input id="email" name="email" v-model="email" v-validate="'required|email'" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                  <label class="control-label" for="email">{{'view.company.fields.email' | translate}} </label><i class="bar"></i>
                                                  <small v-show="errors.has('email')" class="help text-danger">
                                                      {{ errors.first('email') }}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('name_user')}">
                                              <div class="input-group">
                                                  <input type="text" id="name_user" name="name_user" v-model="name_user" v-validate="'required'" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                  <label class="control-label" for="name_user">{{'view.company.fields.name_owner' | translate}} </label><i class="bar"></i>
                                                  <small v-show="errors.has('name_user')" class="help text-danger">
                                                      {{ errors.first('name_user') }}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('description')}">
                                              <div class="input-group">
                                                  <input type="text" id="description" name="description" v-model="description" v-validate="'required'" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                  <label class="control-label" for="description">{{'view.company.fields.description' | translate}} </label><i class="bar"></i>
                                                  <small v-show="errors.has('description')" class="help text-danger">
                                                      {{ errors.first('description') }}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('address')}">
                                              <div class="input-group">
                                                  <input type="text" id="address" name="address" v-model="address" v-validate="'required'" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                  <label class="control-label" for="address">{{'view.company.fields.address' | translate}} </label><i class="bar"></i>
                                                  <small v-show="errors.has('address')" class="help text-danger">
                                                      {{ errors.first('address') }}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('phone')}">
                                              <div class="input-group">
                                                  <input type="text" id="phone" name="phone" v-model="phone" v-validate="'required'" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                  <label class="control-label" for="address">{{'view.company.fields.phone' | translate}} </label><i class="bar"></i>
                                                  <small v-show="errors.has('phone')" class="help text-danger">
                                                      {{ errors.first('phone') }}
                                                  </small>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-md-6 with-icon-right">
                                          <div class="form-group with-icon-right" :class="{'has-error': errors.has('type_company')}">
                                              <div class="input-group">
                                                  <multiselect
                                                      v-model="id_type_company"
                                                      deselect-label="Selected Element"
                                                      label="name"
                                                      placeholder="Select an Element"
                                                      :options="TypeCompany"
                                                      :searchable="false"
                                                      :allow-empty="false"
                                                      >
                                                      <template slot="singleLabel" slot-scope="{ option }">
                                                        {{'view.company.fields.type_company' | translate}}:
                                                        <label style="font-weight:600">{{ option.name }}</label>
                                                      </template>
                                                  </multiselect>
                                                  <!-- <vuestic-simple-select :required="true" :label="'view.company.fields.type_company' | translate" v-model="id_type_company" :options="TypeCompany" option-key="name" />
                                                  <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                  <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                                  <small v-show="errors.has('type_company')" class="help text-danger">
                                                      {{ errors.first('type_company') }}
                                                  </small> -->
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </fieldset>
                              <div class="col-md-3 offset-md-12">
                                  <button class="btn btn-primary btn-sm" type="submit">{{$t('view.company.update')}}</button>
                              </div>
                          </div>
                      </div>

                  </form>
              </vuestic-widget>
          </div>
      </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'form-elements',
  components: {
    Multiselect
  },
  data () {
    return {
      name: '',
      email: '',
      name_user: '',
      description: '',
      address: '',
      phone: '',
      id_type_company: '',
      TypeCompany: [],
    }
  },
  mounted () {
    this.loadTypeCompanysActive()
      .then((data) => {
        this.TypeCompany = data
      })
      .catch(data => {
        this.error = data.message
      })
    var id = this.$route.params.id

    if (id !== null) {
      this.loadCompany(id)
        .then((data) => {
          this.name = data.name
          this.email = data.email
          this.name_user = data.owner.user.name
          this.description = data.description
          this.address = data.direction
          this.phone = data.phone
          this.id_type_company = data.type_company
        })
        .catch(data => {
          this.addToastMessage({
            text: 'Ocurrio un error',
            type: 'success'
          })
        })
    } else {
      this.addToastMessage({
        text: 'Ocurrio un error',
        type: 'success'
      })
    }
  },
  methods: {
    ...mapActions(['loadCompany', 'updateCompany', 'deleteCompany', 'loadTypeCompanysActive', 'addToastMessage']),
    back () {
      this.$router.go(-1)
    },
    editUser () {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id
          this.updateCompany({ id: id, name: this.name, email: this.email, name_user: this.name_user, description: this.description, direction: this.address, phone: this.phone, id_type_company: this.id_type_company.id })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() => this.$router.go(-1), 2000)
            })
            .catch(data => {
              this.error = data.message
              this.errors = data.errors || {}
            })
        }
      })
    },
    cleanform () {
      this.name = null
      this.email = null
    }

  },
  computed: {
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulEmailValid () {
      let isValid = false
      if (this.formFields.email) {
        isValid = this.formFields.email.validated && this.formFields.email.valid
      }
      return isValid
    },
  },
}
</script>
